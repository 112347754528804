<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="文章列表" name="first">
        <Tab1 :title_info="title_info" :classify="1" v-if="activeName == 'first'"></Tab1>
      </el-tab-pane>
      <el-tab-pane label="文章标签" name="second">
        <Tab2 :classify="1" v-if="activeName == 'second'"></Tab2>
      </el-tab-pane>
      <!-- <el-tab-pane label="产品列表" name="three">
        <Tab1 :classify="2" v-if="activeName == 'three'"></Tab1>
      </el-tab-pane>
      <el-tab-pane label="产品分类" name="four">
        <Tab2 :classify="2" v-if="activeName == 'four'"></Tab2>
      </el-tab-pane>
      <el-tab-pane label="方案列表" name="five">
        <Tab1 :classify="3" v-if="activeName == 'five'"></Tab1>
      </el-tab-pane>
      <el-tab-pane label="方案分类" name="six">
        <Tab2 :classify="3" v-if="activeName == 'six'"></Tab2>
      </el-tab-pane> -->
      <el-tab-pane label="草稿箱" name="seven">
        <Tab3 :title_info="title_info" :classify="1" v-if="activeName == 'seven'"></Tab3>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Tab1 from "./customerArticles1.vue";
import Tab2 from "./components/tabs2.vue";
import Tab3 from "./isDrafts.vue";
export default {
  data() {
    return {
      title_info:'文章',
      activeName: "first", //tabs页
      propInfo:{
        iptTitle:'文章'
      },
    };
  },
  components: {
    Tab1,
    Tab2,
    Tab3
  },
  filters: {},
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
